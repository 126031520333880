import React from "react"
import PropTypes from "prop-types"
import { Helmet } from "react-helmet"
import { useStaticQuery, graphql } from "gatsby"

function SEO({ description, lang, meta, title }) {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            defaultDescription: description
            defaultTitle: title
            siteUrl
          }
        }
      }
    `
  )

  const { defaultDescription, defaultTitle, siteUrl } = site.siteMetadata

  const seo = {
    description: description || defaultDescription,
    title: title || defaultTitle,
    url: siteUrl,
  }

  return (
    <Helmet
      htmlAttributes={{
        lang,
      }}
      title={seo.title}
      titleTemplate={`%s | ${defaultTitle}`}
      meta={[
        {
          name: "description",
          content: seo.description,
        },
        {
          property: "og:title",
          content: seo.title,
        },
        {
          property: "og:description",
          content: seo.description,
        },
        {
          property: "og:type",
          content: "website",
        },
        {
          property: "og:url",
          content: seo.url,
        },
        {
          property: "twitter:title",
          content: seo.title,
        },
        {
          property: "twitter:description",
          content: seo.description,
        },
      ].concat(meta)}
    />
  )
}

SEO.defaultProps = {
  lang: "de",
  meta: [],
  description: "RUBIN DuSwap Projekt",
}

SEO.propTypes = {
  description: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.arrayOf(PropTypes.object),
  title: PropTypes.string.isRequired,
}

export default SEO
